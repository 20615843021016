import {useForm, Link} from "@inertiajs/react";

export default function Login() {
    const {data, setData, post, processing, errors} = useForm({
        // Initialize with empty strings instead of undefined
        email: '',
        password: '',
        remember: false
    });

    function onHandleChange(e) {
        const key = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setData(values => ({
            ...values,
            [key]: value
        }));
    }

    function submit(e) {
        e.preventDefault();
        post(route('login'));
    }

    return (
        <div className="h-full absolute w-full bg-gray-50">
            <div className="flex mt-4 flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="h-8 mx-auto w-auto mb-8"
                        src="/storage/images/logo_dark.svg"
                        alt="Conductly"
                    />
                    <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Hey, good to see you again!
                    </h2>
                    <h2 className="mt-4 text-center text-md font-medium leading-9 tracking-tight text-gray-500">
                        Sign in with your account
                    </h2>
                </div>

                <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <form className="space-y-6" onSubmit={submit} method="POST">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={data.email}
                                        onChange={onHandleChange}
                                    />
                                    {errors.email && <div className="text-red-600 mt-2 text-sm">{errors.email}</div>}
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={data.password}
                                        onChange={onHandleChange}
                                    />
                                    {errors.password && <div className="text-red-600 text-sm">{errors.password}</div>}
                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <input
                                        id="remember"
                                        name="remember"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        checked={data.remember}
                                        onChange={onHandleChange}
                                    />
                                    <label htmlFor="remember" className="ml-3 block text-sm leading-6 text-gray-900">
                                        Keep me signed in
                                    </label>
                                </div>

                                <div className="text-sm leading-6">
                                    <Link href={route('forgot.password')} className="font-semibold text-indigo-600 hover:text-indigo-500">
                                        Forgot password?
                                    </Link>
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className={`flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm ${processing ? 'opacity-50' : 'hover:bg-indigo-500'} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                    disabled={processing}
                                >
                                    Sign in
                                </button>
                            </div>
                        </form>
                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Not a current user?{' '}
                        <a href="/register" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                            Register a new account →
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}
